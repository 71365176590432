<template>
    <div class="loginBG">
        <div class="titleBox">看拼音写词语投屏教学</div>
        <div style="text-align:center;margin-top:20px;margin-top:50px">
          <Select v-model="model1" size="large"  placeholder="请选择投屏小程序" style="width:200px"  @on-change="checkQr()">
            <Option v-for="item in cityList" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
        </div>
        
        <div class="checkTba" v-if="isQr && model1" @click="checkTab">切换到账号密码登陆</div>
        <div class="checkTba" v-if="!isQr && model1" @click="checkTab">切换到扫码登陆</div>
        <div class="loginQr" v-if="isQr && model1">
          <img v-if="src" :src="src" alt="" srcset="">
          <div class="loginInfo">微信扫码登录</div>
        
        </div>
          <div class="indos" v-if="isQr && model1">提示，如扫码登录没反应，建议使用360浏览器，或使用账号登录。</div>
        <div class="loginQr" v-if="!isQr " style="width:400px;height:180px">
          <Form ref="formInline" :model="formInline" :rules="ruleInline" inline>
            <Row >
             <Form-item prop="user">
                <Input type="text" size="large" v-model="formInline.user" placeholder="请填写手机号">
                    <Icon type="ios-person-outline" slot="prepend"></Icon>
                </Input>
            </Form-item>
            </Row>
           <Row >
               <Form-item prop="password">
                  <Input type="password" size="large" v-model="formInline.password" placeholder="请填写密码">
                      <Icon type="ios-unlock-outline" slot="prepend"></Icon>
                  </Input>
              </Form-item>
           </Row>
            <Row >
               <Checkbox v-model="setPassword">记住账号密码</Checkbox>
              <Form-item style="text-align: center;">
                  <Button style="width:200px;" type="primary" @click="handleSubmit('formInline')">登录</Button>
              </Form-item>
             </Row>
           
        </Form>
        <div class="infos">提示：进入小程序首页底部“我的”——“设置关联账号”即可使用账号登录</div>
        </div>
         <div class="downBtns" v-if="fromUrl!='app'">
          <div class="topInfos">提示：Ctrl+D 可快速收藏本网页！</div>
          <div class="topInfos">下次在收藏夹可直接打开，更便捷！</div>
        </div>
        <div class="downBtns1" v-if="fromUrl!='app'">
          <div class="topInfos">使用桌面登录器，无需每次输入网址</div>
          <div class="topInfos">请下载保存到桌面上<Button type="primary" @click="frontDownload" class="goback">点击下载</Button></div>
        </div>

         <div class="bottomsBoxBn">南通百捷网络科技有限公司
          <a target="_blank" style="margin-left:40px;color:#fff;text-decoration:underline" href="https://beian.miit.gov.cn/">苏ICP备17059974号-1</a>
          <a target="_blank" style="margin-left:40px;color:#fff;text-decoration:underline" href="https://beian.miit.gov.cn/"><img src="../../public/jing-logo.png" alt=""> 苏公网安备32060202002148号</a>
        </div>
    </div>
</template>
  
  <script>
import axios from "axios";
import qs from "qs"
export default {
    data() {
      return {
        src:'',
        RandomCode:'',
        isQr:true,
        fromUrl:'',
        formInline: {
            user: '',
            password: ''
        },
        ruleInline: {
            user: [
                { required: true, message: '请填写用户绑定手机号', trigger: 'blur' }
            ],
            password: [
                { required: true, message: '请填写密码', trigger: 'blur' },
                // { type: 'string', min: 6, message: '密码长度不能小于6位', trigger: 'blur' }
            ]
        },
        cityList: [
            {
                value: '1',
                label: '看拼音写词语出题器'
            },
            {
                value: '2',
                label: '青檬语文'
            }
        ],
        model1: '',
        items:'',
        setPassword:false
      };
    },
    created() {
      // this.queryQr()
      this.fromUrl = this.$route.query.from
      if(window.localStorage.getItem('userName')){
        this.formInline.user = window.localStorage.getItem('userName')
      }
      if(window.localStorage.getItem('password')){
        this.formInline.password = window.localStorage.getItem('password')
      }
    },
    mounted() {
     
    },
    
    methods: {
      frontDownload() {
        var a = document.createElement("a"); //创建一个<a></a>标签
        a.href ="https://r.bjwxjs.com/exe%E7%A8%8B%E5%BA%8F/%E7%9C%8B%E6%8B%BC%E9%9F%B3%E5%86%99%E8%AF%8D%E8%AF%AD%E6%8A%95%E5%B1%8F.exe"; // 给a标签的href属性值加上地址，注意，这里是绝对路径，不用加 点.
        a.download = "百捷语文投屏.exe"; //设置下载文件文件名，这里加上.xlsx指定文件类型，pdf文件就指定.fpd即可
        a.style.display = "none"; // 障眼法藏起来a标签
        document.body.appendChild(a); // 将a标签追加到文档对象中
        a.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
        a.remove(); // 一次性的，用完就删除a标签
    },
       handleSubmit(name) {
        this.$refs[name].validate((valid) => {
            if (valid) {
              const that = this
              let url = this.model1 == 1?'/v1/login':'/we2/v1/login'
              axios({
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                },
                method: 'POST',
                url: url,
                data: qs.stringify({
                  phone :this.formInline.user,
                  password: this.formInline.password,
                })
              })
              .then((res) => {
                if(res.data.code == 0){
                   window.localStorage.setItem('sessionId',res.data.data.sessionId)
                   if(this.setPassword){
                      window.localStorage.setItem('userName',this.formInline.user)
                      window.localStorage.setItem('password', this.formInline.password)
                   }
                  
                   that.$router.push('topicList')
                }else{
                  that.$Message.error(res.data.msg);
                }
              })
              .catch((err) => {
                console.log(err)
              });
            } else {
                this.$Message.error('表单验证失败!');
            }
        })
    },
      checkTab(){
        this.isQr = !this.isQr
      },
      checkQr(){
        console.log('ee')
        clearTimeout(this.items)
         this.queryQr()
      },
      queryQr(){
        const that = this
        let url = this.model1 == 1?'/v1/wx/qrcode':'/we2/v1/wx/qrcode'
        axios({
           headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          method: 'GET',
          url: url,
        })
        .then((res) => {
          if(res.data.success){
            that.src = res.data.data.QRCodeStr
            that.RandomCode = res.data.data.RandomCode,
            that.queryLoginSatus()
          }else{
            that.$Message.error('获取二维码错误');
          }
          

        })
        .catch((err) => {
          console.log(err)
        });
      },
      queryLoginSatus(){
        const that = this
         let url = this.model1 == 1?'/v1/web/CheckLogin?code='+that.RandomCode:'/we2/v1/web/CheckLogin?code='+that.RandomCode
        axios({
           headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          method: 'GET',
          url: '/v1/web/CheckLogin?code='+that.RandomCode,
          
        })
        .then((res) => {
          if(res.data.success){
            window.localStorage.setItem('sessionId',res.data.data.sessionId)
            that.$router.push('topicList')

          }else{
         this.items = setTimeout(() => {
              that.queryLoginSatus()
            }, 1000);
          }

        })
        .catch((err) => {
          console.log(err)
        });
      },
    },
  };
  </script>
  
  <style scoped>
    .loginBG{
        width: 100%;
        height: 100%;
        background: url('https://r.bjwxjs.com/PC_kpy/login_bg%402x.png') 0 0 / 100% 100% no-repeat;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
    .titleBox{
        font-size: 30px;
        font-weight: normal;
        color: #FFFFFF;
        letter-spacing: 5px;
        text-shadow: 0px 0px 209px rgba(29,92,171,0.74);
        text-align: center;
        margin-top: 40px;
        height: 40px;
        line-height: 40px;
        width: 600px;
        text-align: left;
        padding-left: 30px;
       

    }
    .loginQr{
      width: 240px;
      height: 300px;
      background: #FFFFFF;
      border-radius: 10px;
      backdrop-filter: blur(10px);
      margin:20px auto 0;
      padding: 20px;
    }
    .loginQr img{
        width: 100%;
        height: 200px;
        

    }
    .loginInfo{
      font-size: 20px;
      font-weight: normal;
      color: #303133;
      letter-spacing: 4px;
      text-align: center;
    }
    
    .checkTba{
      font-size: 20px;
      width: 200px;
      height: 40px;
      border-radius: 25px;
      background-color: #fff;
      line-height: 40px;
      text-align: center;
      margin: 30px auto 0;
      color: #5190F9 ;
      cursor: pointer 
    }
    
   .ivu-form-inline .ivu-form-item{
    width: 100%;
   }
   
  .bottomsBoxBn{
    position: absolute;
    width: 100%;
    bottom: 20px;
    left: 0;
    text-align: center;
    color: #fff;
   }
   .bottomsBoxBn img{
    width: 16px;
    height: 16px;
    vertical-align: -2px;
   }
   .downBtns{
    position: absolute;
    right: 30px;
    top: 40px;
    color: #fff;
    font-size: 20px;
  }
  .downBtns1{
    position: absolute;
    right: 30px;
    top: 150px;
    color: #fff;
    font-size: 20px;
  }
  .downA{
    text-decoration-line: none;
    width: 120px;
    height: 30px;
    display: inline-block;
    background-color: #FEBC67;
    color: #fff;
    text-align: center;
    border-radius: 3px;
  }
  .goback{
    margin-left: 30px;
    background-color: #FEBC67;
  }
  /deep/ .ivu-select-large.ivu-select-single .ivu-select-selection{
    height: 60px;
    line-height: 60px;
  }
  /deep/ .ivu-select-large.ivu-select-single .ivu-select-selection .ivu-select-selected-value{
     height: 60px;
     line-height: 60px;
     font-size: 20px;
  }
  /deep/ .ivu-select-arrow{
    font-size: 20px;
  }
  /deep/ .ivu-select-large .ivu-select-item{
    height: 50px;
    line-height: 40px;
    font-size: 20px !important;
    box-sizing: border-box;
  }
  /deep/ .ivu-select-dropdown{
    max-height: 120px !important;
    overflow: hidden;
  }
/deep/ .ivu-select-large.ivu-select-single .ivu-select-selection .ivu-select-placeholder{
  height: 60px;
    line-height: 60px;
    font-size: 20px;
}
.infos{
  font-size: 12px;
  color: #fff;
  text-align: center;
  margin-top: 60px;
  width: 450px !important;
}
.indos{
  font-size: 12px;
  color: #fff;
  text-align: center;
  margin-top: 40px;
}
  </style>
  